import React from 'react';
import styles from '../styles/MobileExcerptItem.module.css';
import { getTagColor, getContrastColor } from '../utils/utils';

const Tags = ({ tags, onTagSelect }) => {
  // Sort the tags alphabetically
  const sortedTags = tags 
    ? [...tags]
        .filter(tag => tag && tag.name)
        .sort((a, b) => a.name.localeCompare(b.name)) 
    : [];

  return (
    <div className={styles.tagsWrapper}>
      {sortedTags.map((tag) => {
        const bgColor = getTagColor(tag.name);
        const textColor = getContrastColor(bgColor);
        return (
          <span 
            key={tag.tags_uuid} 
            className={styles.tag} 
            onClick={() => onTagSelect(tag.name, tag, true)}
            style={{ 
              backgroundColor: bgColor,
              color: textColor
            }}
          >
            {tag.name}
          </span>
        );
      })}
    </div>
  );
};

export default Tags;
