import React, { useState } from 'react';
import '../styles/EpisodeSponsor.css';

const EpisodeSponsor = ({ sponsors }) => {
  const sortedSponsors = [...sponsors].sort((a, b) => 
    a.sponsor_name.localeCompare(b.sponsor_name)
  );

  return (
    <div className="sponsor-container">
      <div className="sponsors-wrapper expanded">
        <div className="sponsors-list">
          {sortedSponsors.map((sponsor) => (
            <a
              key={sponsor.sponsor_name}
              href={sponsor.referral_link}
              target="_blank"
              rel="noopener noreferrer"
              className="sponsor-item"
            >
              <h4 className="sponsor-name">{sponsor.sponsor_name}</h4>
              {sponsor.offer_details && (
                <p className="sponsor-details">{sponsor.mention_context} {sponsor.offer_details}</p>
              )}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EpisodeSponsor;
