import React from 'react';
import styles from '../styles/WaitlistBox.module.css';

const WaitlistBox = () => {
  return (
    <div className={styles.waitlistBox}>
      <h2 className={styles.title}>Be the First to Know</h2>
      <p className={styles.description}>Join the waitlist to get early access at launch!</p>
      <a 
        href="https://forms.gle/gqTEq5SwVGmMudFdA"
        target="_blank" 
        rel="noopener noreferrer" 
        className={styles.button}
      >
        Join Waitlist
      </a>
    </div>
  );
};

export default WaitlistBox;
