import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { postQuery } from '../api/api';

export const submitQuery = createAsyncThunk(
  'query/submitQuery',
  async (queryString, { rejectWithValue }) => {
    try {
      const response = await postQuery(queryString);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const querySlice = createSlice({
  name: 'query',
  initialState: {
    data: null,
    status: 'idle',
    error: null,
  },
  reducers: {
    clearQueryData: (state) => {
      state.data = null;
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitQuery.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(submitQuery.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(submitQuery.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { clearQueryData } = querySlice.actions;

export default querySlice.reducer;
