import React from 'react';
import '../styles/LoadingSpinner.css';

const LoadingSpinner = ({ text = 'Loading...' }) => {
  return (
    <div className="loader-container">
      <div className="loader"></div>
      <div className="loader-text">{text}</div>
    </div>
  );
};

export default LoadingSpinner;
