const colors = [
    '#FFB3BA', // Light Pink
    '#BAFFC9', // Light Green
    '#BAE1FF', // Light Blue
    '#FFFFBA', // Light Yellow
    '#FFD9BA', // Light Peach
    '#E0BBE4', // Light Lavender
    '#D4F0F0', // Light Cyan
    '#FFC3A0', // Light Coral
    '#C4E3B5', // Light Mint
    '#F0E68C', // Khaki
    '#ADD8E6', // Light Blue
    '#F0FFF0', // Honeydew
];

function getTagColor(tag) {
    // Use a hash function to consistently assign colors to tags
    let hash = 0;
    for (let i = 0; i < tag.length; i++) {
        hash = tag.charCodeAt(i) + ((hash << 5) - hash);
    }
    return colors[Math.abs(hash) % colors.length];
}

// New function to determine text color
function getContrastColor(hexColor) {
    // Convert hex to RGB
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);
    
    // Calculate luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    
    // Return black for light backgrounds, white for dark backgrounds
    return luminance > 0.5 ? '#000000' : '#FFFFFF';
}

export { getTagColor, getContrastColor };
