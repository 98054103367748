import React, { useState } from 'react';
import { FaImage } from 'react-icons/fa';
import styles from '../styles/EpisodeImage.module.css';

const EpisodeImage = ({ imageUrl, altText }) => {
  const [hasError, setHasError] = useState(false);

  if (hasError || !imageUrl) {
    return (
      <div className={styles.imageContainer}>
        <div className={styles.imagePlaceholder}>
          <FaImage />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.imageContainer}>
      <img 
        src={imageUrl}
        alt={altText}
        className={styles.image}
        onError={() => setHasError(true)}
      />
    </div>
  );
};

export default EpisodeImage;
