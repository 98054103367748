import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { auth } from '../firebase';
import { setUser, clearUser } from '../store/authSlice';

function AuthStateListener() {
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        dispatch(setUser({
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
          photoURL: user.photoURL,
          emailVerified: user.emailVerified
        }));
      } else {
        dispatch(clearUser());
      }
    });

    return () => unsubscribe();
  }, [dispatch]);

  return null;
}

export default AuthStateListener;
