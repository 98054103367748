import React, { useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaCalendar, FaShareSquare } from 'react-icons/fa';
import EntityList from './EntityList';
import styles from '../styles/ExcerptPlayer.module.css';
import Person from './Person';
import PageHelmet from './PageHelmet';
import MobileExcerptItem from './MobileExcerptItem';
import ShowImage from './ShowImage';
import EpisodeSponsor from './EpisodeSponsor';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EpisodeImageContainer from './EpisodeImageContainer';

const ExcerptPlayer = ({ excerpt, startPlaying = false, onAudioEnded, onNextExcerpt, onPreviousExcerpt }) => {
  const [seekTo, setSeekTo] = useState(null);
  const [isPlaying, setIsPlaying] = useState(startPlaying);
  const navigate = useNavigate();
  const [playerTime, setPlayerTime] = useState(0);

  const handleTimeUpdate = useCallback((time) => {
    setPlayerTime(time);
  }, []);

  const handleDiveIntoEpisode = useCallback(() => {
    const timeOffset = Math.floor(playerTime + excerpt.excerpt.timestamp_start_seconds);
    navigate(`/episodes/${excerpt.episode?.episodes_uuid}?time_offset=${timeOffset}`);
  }, [playerTime, excerpt, navigate]);

  const handleShareClick = () => {
    const currentUrl = window.location.origin + `/excerpts/${excerpt.excerpt.excerpts_uuid}`;
    navigator.clipboard.writeText(currentUrl).then(() => {
      toast.success('Excerpt Link copied to clipboard', {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        className: styles.toastSuccess,
      });
    });
  };

  const handleTagSelect = (tagName, tag) => {
    window.open(`/feed?tags_uuid=${tag.tags_uuid}`, '_blank');
};

  if (!excerpt) return <div>No excerpt found</div>;

  return (
    <div className={`bg-gray-900 text-gray-100 font-sans ${styles.playerContainer}`}>
      <PageHelmet title={excerpt.excerpt.title} />
      <ToastContainer
        toastClassName={styles.customToast}
        progressClassName={styles.customProgress}
      />
      <main className="container mx-auto px-4 py-8 md:py-16">
        <div className={styles.playerMainContent}>
          <div className={styles.episodeHeader}>
            <div className={styles.episodeInfo}>
              <h2 className={styles.excerptTitle}>{excerpt.excerpt.title}</h2>
              <h3 className={styles.episodeTitle}>
                <button 
                  onClick={handleDiveIntoEpisode}
                  className={styles.episodeTitleButton}
                >
                  {excerpt.episode?.title}
                </button>
              </h3>
              <h4 className={styles.showTitle}>
                <Link to={`/shows/${excerpt.show?.shows_uuid}`} className={styles.showTitleLink}>
                  {excerpt.show?.title}
                </Link>
              </h4>
              {excerpt.episode?.published_date && (
                <p className={styles.publishedDate}>
                  <FaCalendar className={styles.calendarIcon} />
                  {new Date(excerpt.episode.published_date).toLocaleDateString()}
                </p>
              )}
              <div className={styles.personList}>
                <div className={styles.personListItems}>
                  <Link to={`/shows/${excerpt.show?.shows_uuid}`}>
                    <ShowImage 
                      src={excerpt.show?.shows_image} 
                      alt={excerpt.show?.title} 
                      size={40}
                    />
                  </Link>
                  {excerpt.guests && excerpt.guests.length > 0 && excerpt.guests.map((person) => (
                    <Person key={person.persons_uuid} person={person} size={40} />
                  ))}
                </div>
                <div className={styles.buttonSection}>
                  <button 
                    className={styles.shareButton}
                    onClick={handleShareClick}
                  >
                    <FaShareSquare className={styles.buttonIcon} />
                    Share
                  </button>
                </div>
              </div>
            </div>
            <EpisodeImageContainer 
              episode={excerpt.episode}
              show={excerpt.show}
              currentlyPlayingExcerpt={excerpt.excerpt}
              onTimeUpdate={handleTimeUpdate}
              isPlaying={isPlaying}
              onNextExcerpt={onNextExcerpt}
              onPreviousExcerpt={onPreviousExcerpt}
              onDiveIntoEpisode={handleDiveIntoEpisode}
              onAudioEnded={onAudioEnded}
              showShareButton={false}
            />
          </div>

          {excerpt.excerpt.entities && excerpt.excerpt.entities.length > 0 && (
            <div className={styles.currentlyPlayingContainer}>
              <h4 className={styles.currentlyPlayingTitle}>Pull It Up Jamie</h4> 
              <EntityList entities={excerpt.excerpt.entities} />
            </div>
          )}

          <div className={styles.currentlyPlayingContainer}>
            <h4 className={styles.currentlyPlayingTitle}>Excerpt Description</h4> 
            <MobileExcerptItem 
              key={excerpt.excerpt.excerpts_uuid}
              excerpt={excerpt.excerpt} 
              onTagSelect={handleTagSelect}
              onListenExcerpt={() => {}}
            />
          </div>

          {(excerpt !== null && excerpt.episode.sponsors.length > 0) && (
            <div className={styles.currentlyPlayingContainer}>
              <h4 className={styles.sponsorsTitle}>Support this Podcast</h4> 
              <EpisodeSponsor sponsors={excerpt.episode.sponsors} />
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default ExcerptPlayer;
