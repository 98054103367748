import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PersonImage from './PersonImage';
import styles from '../styles/Person.module.css';

const Person = ({ person, size = 80 }) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/persons/${person.persons_uuid}`);
  };

  return (
    <div 
      className={styles.personContainer}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleClick}
    >
      <PersonImage src={person.public_image_url} alt={person.name} size={size} />
      {isHovered && (
        <div className={styles.personInfo}>
          <h3 className={styles.personName}>{person.name}</h3>
        </div>
      )}
    </div>
  );
};

export default Person;
