import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { auth } from '../firebase';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import AuthenticationSpinner from './AuthenticationSpinner';
import styles from '../styles/AuthenticationPage.module.css';
import PageHelmet from './PageHelmet';

function AuthenticationPage() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, isLoading: authLoading } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isAuthenticated) {
      // Check if there's a stored redirect path
      const redirectPath = localStorage.getItem('redirectAfterAuth');
      if (redirectPath) {
        localStorage.removeItem('redirectAfterAuth');
        navigate(redirectPath);
      } else {
        // If no stored path, redirect to profile or default page
        navigate('/profile');
      }
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleGoogleSignIn = async () => {
    if (loading) return;

    setLoading(true);
    setError(null);
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      if (error.code === 'auth/user-cancelled' || error.code === 'auth/popup-closed-by-user') {
        setError('Sign-in cancelled. Please try again if you want to sign in.');
      } else {
        setError(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  if (authLoading) {
    return <AuthenticationSpinner />;
  }

  return (
    <div className={styles.container}>
      <PageHelmet title="Sign In" />
      <main className={styles.main}>
        <h1 className={styles.title}>Sign In</h1>
        <p className={styles.description}>
          Join Pod Nebula to discover your next favorite podcast.
        </p>
        <div className={styles.buttonContainer}>
          {loading ? (
            <button 
              className={`${styles.button} ${styles.loadingButton}`}
              disabled
            >
              Signing in...
            </button>
          ) : (
            <button
              onClick={handleGoogleSignIn}
              className={`${styles.button} ${styles.googleButton}`}
            >
              <img 
                src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" 
                alt="Google logo" 
                className={styles.googleLogo}
              />
              Sign in with Google
            </button>
          )}
        </div>
        {error && <p className={styles.errorMessage}>{error}</p>}
      </main>
    </div>
  );
}

export default AuthenticationPage;
