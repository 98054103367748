import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getShowByUUID, getShowEpisodes, getShowTopics } from '../api/api';

export const fetchShowByUUID = createAsyncThunk(
  'shows/fetchShowByUUID',
  async (uuid) => {
    const response = await getShowByUUID(uuid);
    return response;
  }
);

export const fetchShowEpisodes = createAsyncThunk(
  'shows/fetchShowEpisodes',
  async ({ uuid, params }) => {
    const response = await getShowEpisodes(uuid, params);
    return response;
  }
);

export const fetchShowTopics = createAsyncThunk(
  'shows/fetchShowTopics',
  async ({ uuid, params }) => {
    const response = await getShowTopics(uuid, params);
    return response;
  }
);

const showInfoSlice = createSlice({
  name: 'showInfo',
  initialState: {
    data: null,
    episodes: {
      items: [],
      totalCount: 0,
      currentPage: 1,
    },
    topics: {
      items: [],
      totalCount: 0,
      currentPage: 1,
    },
    loading: false,
    episodesLoading: false,
    topicsLoading: false,
    error: null,
  },
  reducers: {
    clearShowData: (state) => {
      return {
        data: null,
        episodes: {
          items: [],
          totalCount: 0,
          currentPage: 1,
        },
        topics: {
          items: [],
          totalCount: 0,
          currentPage: 1,
        },
        loading: false,
        episodesLoading: false,
        topicsLoading: false,
        error: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchShowByUUID.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchShowByUUID.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchShowByUUID.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchShowEpisodes.pending, (state) => {
        state.episodesLoading = true;
      })
      .addCase(fetchShowEpisodes.fulfilled, (state, action) => {
        state.episodesLoading = false;
        state.episodes = {
          items: action.payload.episodes,
          totalCount: action.payload.total_count,
          currentPage: action.payload.current_page,
        };
      })
      .addCase(fetchShowEpisodes.rejected, (state, action) => {
        state.episodesLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchShowTopics.pending, (state) => {
        state.topicsLoading = true;
      })
      .addCase(fetchShowTopics.fulfilled, (state, action) => {
        state.topicsLoading = false;
        state.topics = {
          items: action.payload.topics,
          totalCount: action.payload.total_count,
          currentPage: action.payload.current_page,
        };
      })
      .addCase(fetchShowTopics.rejected, (state, action) => {
        state.topicsLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { clearShowData } = showInfoSlice.actions;

export default showInfoSlice.reducer;
