import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createPortal } from 'react-dom';
import { fetchExploreResults, clearExploreData } from '../store/exploreSlice';
import ExcerptsTable from './ExcerptsTable';
import ExcerptPlayer from './ExcerptPlayer';
import LoadingSpinner from './LoadingSpinner';
import AuthenticationSpinner from './AuthenticationSpinner';
import PageHelmet from './PageHelmet';
import styles from '../styles/ExplorePage.module.css';
import ShowFilterModal from './ShowFilterModal';
import ShowImage from './ShowImage';
import TopicsList from './TopicsList';
import Pagination from './Pagination';
import PersonFilterModal from './PersonFilterModal';

const ExplorePage = () => {
  const [selectedExcerpt, setSelectedExcerpt] = useState(null);
  const [currentExcerptIndex, setCurrentExcerptIndex] = useState(null);
  const [overlayRoot, setOverlayRoot] = useState(null);
  const [showFilterModalOpen, setShowFilterModalOpen] = useState(false);
  const [selectedShows, setSelectedShows] = useState([]);
  const [selectedShowsDetails, setSelectedShowsDetails] = useState({});
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedTopicsDetails, setSelectedTopicsDetails] = useState({});
  const [selectedPersons, setSelectedPersons] = useState([]);
  const [selectedPersonsDetails, setSelectedPersonsDetails] = useState({});
  const [personFilterModalOpen, setPersonFilterModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { data, status, error, currentPage, hasMore } = useSelector((state) => state.explore);
  const { isAuthenticated, isLoading: authLoading } = useSelector((state) => state.auth);

  useEffect(() => {
    setOverlayRoot(document.getElementById('overlay-root'));
    return () => {
      dispatch(clearExploreData());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!authLoading && isAuthenticated) {
      dispatch(fetchExploreResults({ 
        page: 1,
        limit: 10,
        shows: selectedShows,
        topics: selectedTopics.map(uuid => uuid),
        persons: selectedPersons
      }));
    }
  }, [selectedShows, selectedTopics, selectedPersons, dispatch, authLoading, isAuthenticated]);

  const handlePageChange = (newPage) => {
    dispatch(fetchExploreResults({ 
      page: newPage,
      limit: 10,
      shows: selectedShows,
      topics: selectedTopics.map(uuid => uuid)
    }));
  };

  const handleSelectExcerpt = (excerpt, index) => {
    setSelectedExcerpt(excerpt);
    setCurrentExcerptIndex(index);
  };

  const handleCloseExcerpt = () => {
    setSelectedExcerpt(null);
    setCurrentExcerptIndex(null);
  };

  const goToNextExcerpt = () => {
    if (data?.excerpts && currentExcerptIndex < data.excerpts.length - 1) {
      const nextIndex = currentExcerptIndex + 1;
      setCurrentExcerptIndex(nextIndex);
      setSelectedExcerpt(data.excerpts[nextIndex]);
    }
  };

  const goToPreviousExcerpt = () => {
    if (currentExcerptIndex > 0) {
      const prevIndex = currentExcerptIndex - 1;
      setCurrentExcerptIndex(prevIndex);
      setSelectedExcerpt(data.excerpts[prevIndex]);
    }
  };

  const handleShowSelect = (show) => {
    setSelectedShows(prev => {
      const isAlreadySelected = prev.includes(show.shows_uuid);
      
      if (!isAlreadySelected) {
        setSelectedShowsDetails(prev => ({
          ...prev,
          [show.shows_uuid]: show
        }));
      }
      
      return isAlreadySelected 
        ? prev.filter(uuid => uuid !== show.shows_uuid)
        : [...prev, show.shows_uuid];
    });
  };

  const renderOverlay = () => {
    if (!selectedExcerpt || !overlayRoot) return null;

    return createPortal(
      <div 
        className={styles.overlay} 
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            handleCloseExcerpt();
          }
        }}
      >
        <div className={styles.overlayContent}>
          <ExcerptPlayer
            excerpt={selectedExcerpt}
            startPlaying={true}
            onAudioEnded={goToNextExcerpt}
            onNextExcerpt={goToNextExcerpt}
            onPreviousExcerpt={goToPreviousExcerpt}
          />
          <button className={styles.closeButton} onClick={handleCloseExcerpt}>
            ×
          </button>
        </div>
      </div>,
      overlayRoot
    );
  };

  const getErrorMessage = (error) => {
    if (typeof error === 'string') return error;
    if (error?.message) return error.message;
    if (error?.error) return error.error;
    return 'An unexpected error occurred';
  };

  const getSelectedShowDetails = (showUuid) => {
    return selectedShowsDetails[showUuid];
  };

  const handleTopicSelect = (topicName, topic) => {
    setSelectedTopics(prev => {
      const isAlreadySelected = prev.includes(topic.tags_uuid);
      
      if (!isAlreadySelected) {
        setSelectedTopicsDetails(prev => ({
          ...prev,
          [topic.tags_uuid]: topic
        }));
      }
      
      return isAlreadySelected 
        ? prev.filter(uuid => uuid !== topic.tags_uuid)
        : [...prev, topic.tags_uuid];
    });
  };

  const getSelectedTopicDetails = (topicUuid) => {
    return selectedTopicsDetails[topicUuid];
  };

  const handlePersonSelect = (person) => {
    setSelectedPersons(prev => {
      const isAlreadySelected = prev.includes(person.persons_uuid);
      
      if (!isAlreadySelected) {
        setSelectedPersonsDetails(prev => ({
          ...prev,
          [person.persons_uuid]: person
        }));
      }
      
      return isAlreadySelected 
        ? prev.filter(uuid => uuid !== person.persons_uuid)
        : [...prev, person.persons_uuid];
    });
  };

  const getSelectedPersonDetails = (personUuid) => {
    return selectedPersonsDetails[personUuid];
  };

  if (authLoading) {
    return <AuthenticationSpinner />;
  }

  if (!isAuthenticated) {
    return null;
  }

  const renderExcerpts = () => {
    if (status === 'loading' && currentPage === 1) {
      return (
        <div className={styles.excerptsLoadingContainer}>
          <LoadingSpinner text="Loading excerpts..." />
        </div>
      );
    }
    
    if (status === 'failed') {
      return (
        <div className={styles.error}>
          Error: {getErrorMessage(error)}
        </div>
      );
    }
  
    if (status === 'succeeded' && data) {
      if (!data.excerpts || data.excerpts.length === 0) {
        return <div className={styles.noResults}>No results found</div>;
      }
  
      return (
        <div>
          <div className={styles.excerptTableContainer}>
            <ExcerptsTable 
              excerpts={data.excerpts} 
              onSelectExcerpt={handleSelectExcerpt}
              onTagSelect={handleTopicSelect}
            />
          </div>
          {data.pagination && (
            <Pagination
              currentPage={data.pagination.page}
              totalItems={data.pagination.total}
              itemsPerPage={data.pagination.limit}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      );
    }
  
    return null;
  };

  return (
    <>
      <PageHelmet title="Explore" />
      <h1 className={styles.pageTitle}>Explore</h1>
      <div className={styles.sectionContainer}>
        <h2 className={styles.headerTitle}>Filter by Shows</h2>
        <div className={styles.selectedShows}>
          {selectedShows.map(showUuid => {
            const show = getSelectedShowDetails(showUuid);
            if (!show) return null;
            
            return (
              <div key={showUuid} className={styles.selectedShow}>
                <ShowImage 
                  src={show.shows_image} 
                  alt={show.title} 
                  size={32}
                />
                <span className={styles.selectedShowTitle}>{show.title}</span>
                <button 
                  className={styles.removeShowButton}
                  onClick={() => handleShowSelect(show)}
                >
                  ×
                </button>
              </div>
            );
          })}
          <button 
            className={styles.addShowButton}
            onClick={() => setShowFilterModalOpen(true)}
          >
            +
          </button>
        </div>
      </div>

      <div className={styles.sectionContainer}>
        <h2 className={styles.headerTitle}>Filter by People</h2>
        <div className={styles.selectedPersons}>
          {selectedPersons.map(personUuid => {
            const person = getSelectedPersonDetails(personUuid);
            if (!person) return null;
            
            return (
              <div key={personUuid} className={styles.selectedPerson}>
                {person.public_image_url && (
                  <img 
                    src={person.public_image_url} 
                    alt={person.name} 
                    className={styles.selectedPersonImage}
                  />
                )}
                <span className={styles.selectedPersonName}>{person.name}</span>
                <button 
                  className={styles.removePersonButton}
                  onClick={() => handlePersonSelect(person)}
                >
                  ×
                </button>
              </div>
            );
          })}
          <button 
            className={styles.addPersonButton}
            onClick={() => setPersonFilterModalOpen(true)}
          >
            +
          </button>
        </div>
      </div>

      <div className={styles.sectionContainer}>
        <h2 className={styles.headerTitle}>Filter by Topics</h2>
        
        {selectedTopics.length > 0 && <div className={styles.selectedTopics}>
          {selectedTopics.map(topicUuid => {
            const topic = getSelectedTopicDetails(topicUuid);
            if (!topic) return null;
            
            return (
              <div key={topicUuid} className={styles.selectedTopic}>
                <span className={styles.selectedTopicName}>{topic.name}</span>
                <button 
                  className={styles.removeTopicButton}
                  onClick={() => handleTopicSelect(topic.name, topic)}
                >
                  ×
                </button>
              </div>
            );
          })}
        </div>}
        {data && data.topics.length == 0 ? <div className={styles.noResults}>No topics found</div> : (
        <TopicsList
          title=""
          topics={(data?.topics || []).filter(topic => 
            !selectedTopics.includes(topic.tags_uuid)
          )}
          loading={status === 'loading'}
          includePagination={false}
          onTopicSelect={handleTopicSelect}
        />
        )}
      </div>
      <div className={styles.sectionContainer}>
          <h2 className={styles.headerTitle}>
            {selectedShows.length > 0 || selectedTopics.length > 0 || selectedPersons.length > 0 
              ? 'Matching Excerpts ' 
              : 'Excerpts '} 
            ({data && data.pagination ? data.pagination.total : 0})
          </h2>
          {renderExcerpts()}
      </div>
      {showFilterModalOpen && (
          <ShowFilterModal
            onClose={() => setShowFilterModalOpen(false)}
            selectedShows={selectedShows}
            onShowSelect={handleShowSelect}
          />
        )}
      {personFilterModalOpen && (
        <PersonFilterModal
          onClose={() => setPersonFilterModalOpen(false)}
          selectedPersons={selectedPersons}
          onPersonSelect={handlePersonSelect}
          showsUuids={selectedShows}
        />
      )}
      {renderOverlay()}
    </>
  );
};

export default ExplorePage;
