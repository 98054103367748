import React from 'react';
import { Radio, Search, Star } from 'lucide-react';
import WaitlistBox from './WaitlistBox';
import styles from '../styles/LandingPage.module.css';
import PageHelmet from './PageHelmet';

const LandingPage = () => {
  return (
    <div className={styles.landingPage}>
      <PageHelmet title="Welcome" />
      <main className={styles.container}>
        <WaitlistBox />
        <h1 className={styles.title}>Discover Your Next Favorite Podcast</h1>
        <p className={styles.subtitle}>
          A new podcast discovery experience powered by large language models.
          Explore rich connections between Podcasts, People, and Ideas.
        </p>
        <div className={styles.featureContainer}>
          <FeatureCard 
            icon={<Search className="w-10 h-10 md:w-12 md:h-12 text-purple-400" />}
            title="Rich Discovery"
            description="Find podcasts tailored to your interests"
          />
          <FeatureCard 
            icon={<Star className="w-10 h-10 md:w-12 md:h-12 text-purple-400" />}
            title="Primary Sources"
            description="Hear from people in their own words"
          />
          <FeatureCard 
            icon={<Radio className="w-10 h-10 md:w-12 md:h-12 text-purple-400" />}
            title="Insights"
            description="See connections between ideas across podcasts"
          />
        </div>
      </main>
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => {
  return (
    <div className={styles.featureCard}>
      <div className={styles.featureIcon}>{icon}</div>
      <h3 className={styles.featureTitle}>{title}</h3>
      <p className={styles.featureDescription}>{description}</p>
    </div>
  );
};

export default LandingPage;
