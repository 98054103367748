import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { User } from 'lucide-react';
import { getUserPreferences } from '../api/api';
import { clearUser } from '../store/authSlice';
import { auth } from '../firebase';
import AuthenticationSpinner from './AuthenticationSpinner';
import LoadingSpinner from './LoadingSpinner';
import PageHelmet from './PageHelmet';

function UserProfile() {
  const { user, isAuthenticated, isLoading: authLoading } = useSelector((state) => state.auth);
  const [preferences, setPreferences] = useState(null);
  const [error, setError] = useState(null);
  const [isLoadingPreferences, setIsLoadingPreferences] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!authLoading) {
      if (isAuthenticated) {
        fetchUserPreferences();
      } else {
        navigate('/auth');
      }
    }
  }, [isAuthenticated, authLoading, navigate]);

  const fetchUserPreferences = async () => {
    setIsLoadingPreferences(true);
    try {
      const data = await getUserPreferences();
      setPreferences(data);
    } catch (error) {
      setError(error.message || 'An error occurred while fetching user preferences');
    } finally {
      setIsLoadingPreferences(false);
    }
  };

  const handleSignOut = () => {
    auth.signOut().then(() => {
      dispatch(clearUser());
      navigate('/auth');
    });
  };

  if (authLoading) {
    return <AuthenticationSpinner />;
  }

  if (!isAuthenticated) {
    return null;
  }

  return (
    <main className="container mx-auto px-4 py-8 md:py-16 text-center">
        <PageHelmet title="Profile" />
        <h1 className="text-3xl md:text-5xl font-bold mb-4 md:mb-6">User Profile</h1>
        <div className="bg-gray-800 p-6 rounded-lg max-w-md mx-auto">
          {user.photoURL ? (
            <img src={user.photoURL} alt="Profile" className="w-24 h-24 rounded-full mx-auto mb-4" />
          ) : (
            <User className="w-24 h-24 text-purple-400 mx-auto mb-4" />
          )}
          <p className="text-lg md:text-xl mb-2">
            <span className="font-bold">Name:</span> {user.displayName || 'N/A'}
          </p>
          <p className="text-lg md:text-xl mb-4">
            <span className="font-bold">Email:</span> {user.email}
          </p>
          <button
            onClick={handleSignOut}
            className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-lg transition-colors duration-300"
          >
            Sign Out
          </button>
          
          <h2 className="text-2xl font-bold mt-6 mb-4">Backend User Info</h2>
          {error ? (
            <p className="text-red-400">{error}</p>
          ) : isLoadingPreferences ? (
            <LoadingSpinner text="Loading user info..." />
          ) : preferences ? (
            <pre className="text-left bg-gray-700 p-4 rounded overflow-auto">
              {JSON.stringify(preferences, null, 2)}
            </pre>
          ) : (
            <p>No preferences available.</p>
          )}
        </div>
      </main>
  );
}

export default UserProfile;
