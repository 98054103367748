import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LandingPage from './components/LandingPage';
import AboutPage from './components/AboutPage';
import HealthCheckPage from './components/HealthCheckPage';
import * as ga from './utils/googleAnalytics';
import AuthenticationPage from './components/AuthenticationPage';
import UserProfile from './components/UserProfile';
import ShowsPage from './components/ShowsPage';
import AuthStateListener from './components/AuthStateListener';
import NotFoundPage from './components/NotFoundPage';
import EpisodesPage from './components/EpisodesPage';
import MobilePlayer from './components/MobilePlayer';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import styles from './styles/App.module.css';
import ShowPage from './components/ShowPage';
import PersonPage from './components/PersonPage';
import PageHelmet from './components/PageHelmet';
import ExcerptPage from './components/ExcerptPage';
import BrowsePage from './components/BrowsePage';
import FeedPage from './components/FeedPage';
import SearchPage from './components/SearchPage';
import FeaturesPage from './components/FeaturesPage';
import ExplorePage from './components/ExplorePage';

const RouteTracker = () => {
  const location = useLocation();

  React.useEffect(() => {
    ga.pageview(location.pathname + location.search);
  }, [location]);

  return null;
};

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Router>
      <AuthStateListener />
      <PageHelmet />
      <div className={`${styles.app} ${isMobile ? styles.mobileApp : ''}`}>
        {!isMobile && <NavBar />}
        {isMobile && <NavBar />}
        <div className={`${styles.content} ${isMobile ? styles.mobileContent : styles.desktopContent}`}>
          <RouteTracker />
          <Routes>
            <Route path="/" element={isAuthenticated ? <FeedPage /> : <LandingPage />} />
            <Route path="/about" element={isAuthenticated ? <FeaturesPage /> : <AboutPage />} />
            <Route path="/auth" element={<AuthenticationPage />} />
            <Route path="/browse" element={<BrowsePage />}/>
            <Route path="/explore" element={<ExplorePage />}/>
            <Route path="/episodes/:uuid" element={<MobilePlayer />} />
            <Route path="/excerpts/:uuid" element={<ExcerptPage />} />
            <Route path="/episodes" element={<EpisodesPage />} />
            <Route path="/feed" element={<FeedPage />} />
            <Route path="/healthcheck" element={<HealthCheckPage />} />
            <Route path="/persons/:uuid" element={<PersonPage />} />
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/search" element={<SearchPage />} />
            <Route path="/shows" element={<ShowsPage />} />
            <Route path="/shows/:uuid" element={<ShowPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
        {isMobile && <NavBar />}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
