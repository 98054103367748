import React from 'react';
import { Link } from 'react-router-dom';
import { Home } from 'lucide-react';
import '../styles/NotFoundPage.css';
import PageHelmet from './PageHelmet';

function NotFoundPage() {
  return (
    <div className="not-found-page">
      <PageHelmet title="Page Not Found" />
      <main className="not-found-container not-found-main">
        <h1 className="not-found-title">404 - Page Not Found</h1>
        <p className="not-found-message">
          This page does not exist.
        </p>
        <Link 
          to="/" 
          className="not-found-button"
        >
          <Home className="not-found-button-icon" />
          Open Home Page
        </Link>
      </main>
    </div>
  );
}

export default NotFoundPage;
