import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getPersonByUUID, getPersonEpisodes } from '../api/api';

export const fetchPersonByUUID = createAsyncThunk(
  'persons/fetchPersonByUUID',
  async (uuid) => {
    const response = await getPersonByUUID(uuid);
    return response;
  }
);

export const fetchPersonEpisodes = createAsyncThunk(
  'persons/fetchPersonEpisodes',
  async ({ uuid, params }) => {
    const response = await getPersonEpisodes(uuid, params);
    return response;
  }
);

const personInfoSlice = createSlice({
  name: 'personInfo',
  initialState: {
    data: null,
    episodes: {
      items: [],
      totalCount: 0,
      currentPage: 1,
    },
    loading: false,
    episodesLoading: false,
    error: null,
  },
  reducers: {
    clearPersonData: (state) => {
      state.data = null;
      state.episodes = {
        items: [],
        totalCount: 0,
        currentPage: 1,
      };
      state.loading = false;
      state.episodesLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPersonByUUID.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPersonByUUID.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchPersonByUUID.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchPersonEpisodes.pending, (state) => {
        state.episodesLoading = true;
      })
      .addCase(fetchPersonEpisodes.fulfilled, (state, action) => {
        state.episodesLoading = false;
        state.episodes = {
          items: action.payload.episodes,
          totalCount: action.payload.total_count,
          currentPage: action.payload.current_page,
        };
      })
      .addCase(fetchPersonEpisodes.rejected, (state, action) => {
        state.episodesLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { clearPersonData } = personInfoSlice.actions;

export default personInfoSlice.reducer;
