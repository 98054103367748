import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getEpisodeByUUID } from '../api/api';

export const fetchEpisodeByUUID = createAsyncThunk(
  'episodes/fetchEpisodeByUUID',
  async (uuid) => {
    const response = await getEpisodeByUUID(uuid);
    return response;
  }
);

const episodeInfoSlice = createSlice({
  name: 'episodeInfo',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {
    clearEpisodeData: (state) => {
      state.data = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEpisodeByUUID.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchEpisodeByUUID.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchEpisodeByUUID.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { clearEpisodeData } = episodeInfoSlice.actions;
export default episodeInfoSlice.reducer;
