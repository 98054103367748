import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MdHome, MdHeadphones, MdPerson, MdSearch } from 'react-icons/md';
import { PiSquaresFourBold } from "react-icons/pi";
import styles from '../styles/NavBar.module.css';

const NavBar = () => {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isActive = (path) => {
    return location.pathname === path ? styles.activeLink : '';
  };

  if (isMobile) {
    return (
      <>
        <nav className={`${styles.navBar} ${styles.mobileTopNav}`}>
          <div className={styles.mobileTopNavContainer}>
            <Link to="/" className={styles.logo}>Pod Nebula</Link>
            <Link to="/about" className={`${styles.link} ${isActive('/about')}`}>About</Link>
          </div>
        </nav>
        <nav className={`${styles.navBar} ${styles.mobileBottomNav}`}>
          <div className={styles.mobileNavContainer}>
            <Link to="/" className={`${styles.mobileLink} ${isActive('/')}`}>
              <MdHome className={styles.mobileIcon} />
              <span className={styles.mobileLinkText}>Home</span>
            </Link>
            <Link to="/browse" className={`${styles.mobileLink} ${isActive('/browse')}`}>
              <PiSquaresFourBold className={styles.mobileIcon} />
              <span className={styles.mobileLinkText}>Browse</span>
            </Link>
            <Link to="/profile" className={`${styles.mobileLink} ${isActive('/profile')}`}>
              <MdPerson className={styles.mobileIcon} />
              <span className={styles.mobileLinkText}>Profile</span>
            </Link>
            <Link to="/search" className={`${styles.mobileLink} ${isActive('/search')}`}>
              <MdSearch className={styles.mobileIcon} />
              <span className={styles.mobileLinkText}>Search</span>
            </Link>
          </div>
        </nav>
      </>
    );
  }

  return (
    <nav className={`${styles.navBar} ${styles.desktopNav}`}>
      <div className={styles.containerWrapper}>
        <div className={styles.innerContainer}>
          <Link to="/" className={styles.logo}>Pod Nebula</Link>
          <div className={styles.desktopLinks}>
            <Link to="/about" className={`${styles.link} ${isActive('/about')}`}>About</Link>
            <Link to="/explore" className={`${styles.link} ${isActive('/explore')}`}>Explore</Link>
            <Link to="/browse" className={`${styles.link} ${isActive('/browse')}`}>Browse</Link>
            <Link to="/profile" className={`${styles.link} ${isActive('/profile')}`}>Profile</Link>
            <Link to="/search" className={`${styles.link} ${isActive('/search')}`}>Search</Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
