import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getEpisodes } from '../api/api';

export const fetchEpisodes = createAsyncThunk(
  'episodes/fetchEpisodes',
  async () => {
    const response = await getEpisodes();
    return response;
  }
);

const episodesSlice = createSlice({
  name: 'episodes',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEpisodes.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchEpisodes.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchEpisodes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default episodesSlice.reducer;
