import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import { FaPlayCircle, FaPauseCircle, FaStepBackward, FaStepForward } from 'react-icons/fa';
import { RiForward30Line, RiReplay15Line } from "react-icons/ri";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useLocation } from 'react-router-dom';
import '../styles/MobileAudioPlayer.css';

function MobileAudioPlayer({ sourceAudioUrl, onTimeUpdate, seekTo, isPlaying, onNextExcerpt, onPreviousExcerpt, title, artist, artwork, onAudioEnded }) {
  const [audioUrl, setAudioUrl] = useState('');
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [played, setPlayed] = useState(0);
  const [seeking, setSeeking] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const playerRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (sourceAudioUrl) {
      setIsLoading(true);
      setIsReady(false);
      setAudioUrl(sourceAudioUrl);
    } else {
      console.error('Invalid or missing audio URL');
    }
  }, [sourceAudioUrl]);

  useEffect(() => {
    if (isReady && playerRef.current) {
      const searchParams = new URLSearchParams(location.search);
      const timeOffset = parseInt(searchParams.get('time_offset'), 10) || 0;
      
      if (seekTo !== undefined && seekTo !== null) {
        playerRef.current.seekTo(seekTo, 'seconds');
      } else if (timeOffset > 0) {
        playerRef.current.seekTo(timeOffset, 'seconds');
      }
    }
  }, [seekTo, isReady, location.search]);

  useEffect(() => {
    setPlaying(isPlaying);
  }, [isPlaying]);

  useEffect(() => {
    if ('mediaSession' in navigator) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: title,
        artist: artist,
        artwork: [
          { src: artwork, sizes: '512x512', type: 'image/png' }
        ]
      });

      navigator.mediaSession.setActionHandler('play', handlePlay);
      navigator.mediaSession.setActionHandler('pause', handlePause);
      // Apple-specific lock screen controls default to 10-second increments
      navigator.mediaSession.setActionHandler('seekbackward', () => handleRewind(10));
      if (onNextExcerpt !== undefined && onNextExcerpt !== null) {
        navigator.mediaSession.setActionHandler('nexttrack', onNextExcerpt);
      } else {
        // Apple-specific lock screen controls default to 10-second increments
        navigator.mediaSession.setActionHandler('seekforward', () => handleForward(10));
      }
    }
  }, [title, artist, artwork]);

  const handleReady = () => {
    setIsReady(true);
    setIsLoading(false);
  };

  const handlePlay = () => {
    setPlaying(true);
  };

  const handlePause = () => {
    setPlaying(false);
  };

  const handlePlayPause = () => {
    if (isReady) {
      setPlaying(!playing);
    }
  };

  const handleRewind = (seconds) => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      playerRef.current.seekTo(Math.max(currentTime - seconds, 0), 'seconds');
    }
  };

  const handleForward = (seconds) => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      const duration = playerRef.current.getDuration();
      playerRef.current.seekTo(Math.min(currentTime + seconds, duration), 'seconds');
    }
  };

  const handleProgress = (state) => {
    if (!seeking) {
      setPlayed(state.played);
      if (onTimeUpdate) {
        onTimeUpdate(state.playedSeconds);
      }
    }
  };

  const handleDuration = (duration) => {
    setDuration(duration);
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleSkipBack = () => {
    if (onPreviousExcerpt) {
      onPreviousExcerpt();
    }
  };

  const handleSkipForward = () => {
    if (onNextExcerpt) {
      onNextExcerpt();
    }
  };

  return (
    <div className="player-container">
      <div className="controls-wrapper">
        <div className="custom-controls">
          {onPreviousExcerpt && (
            <button className="control-button" onClick={handleSkipBack} disabled={isLoading || !onPreviousExcerpt}>
            <FaStepBackward />
          </button>
          )}
          <button className="control-button" onClick={() => handleRewind(15)} disabled={isLoading}>
            <RiReplay15Line />
          </button>
          <button className="control-button play-pause-button" onClick={handlePlayPause} disabled={isLoading}>
            {isLoading || !isReady ? (
              <AiOutlineLoading3Quarters className="loading-icon" />
            ) : playing ? (
              <FaPauseCircle />
            ) : (
              <FaPlayCircle />
            )}
          </button>
          <button className="control-button" onClick={() => handleForward(30)} disabled={isLoading}>
            <RiForward30Line />
          </button>
          {onNextExcerpt && (
            <button className="control-button" onClick={handleSkipForward} disabled={isLoading || !onNextExcerpt}>
              <FaStepForward />
            </button>
          )}
        </div>
      </div>
      <div className="time-display">
        {formatTime(duration * played)} / {formatTime(duration)}
      </div>
      <ReactPlayer
        className="styled-react-player"
        ref={playerRef}
        url={audioUrl}
        playing={playing}
        controls={false}
        height="0"
        width="0"
        config={{
          file: {
            forceAudio: true,
          },
        }}
        onReady={handleReady}
        onPlay={handlePlay}
        onPause={handlePause}
        onProgress={handleProgress}
        onDuration={handleDuration}
        onEnded={onAudioEnded ? onAudioEnded : null}
      />
    </div>
  );
}

export default MobileAudioPlayer;
