import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchEpisodes } from '../store/episodesSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';
import AuthenticationSpinner from './AuthenticationSpinner';
import '../styles/ShowsPage.css';
import PageHelmet from './PageHelmet';

const EpisodesPage = () => {
    const dispatch = useDispatch();
    const { data, loading: showsLoading, error } = useSelector((state) => state.episodes);
    const { isAuthenticated, isLoading: authLoading } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const location = useLocation();
    const [sortedEpisodes, setSortedEpisodes] = useState([]);

    useEffect(() => {
        if (!authLoading) {
            if (isAuthenticated) {
                dispatch(fetchEpisodes());
            } else {
                // Store the current path in local storage
                localStorage.setItem('redirectAfterAuth', location.pathname);
                // Redirect to auth page with a 'from' parameter
                navigate('/auth?from=' + encodeURIComponent(location.pathname));
            }
        }
    }, [dispatch, isAuthenticated, authLoading, navigate, location]);

    useEffect(() => {
        if (data && data.episodes) {
            const sorted = [...data.episodes].sort((a, b) => new Date(b.published_date) - new Date(a.published_date));
            setSortedEpisodes(sorted);
        }
    }, [data]);

    if (authLoading) {
        return <AuthenticationSpinner />;
    }

    if (!isAuthenticated) {
        return null;
    }

    if (showsLoading) {
        return <LoadingSpinner text="Loading podcast episodes..." />;
    }
  
    if (error) {
        return <div>Error: {error}</div>;
    }

    const handleEpisodeClick = (episodeUuid) => {
        navigate(`/episodes/${episodeUuid}`);
    };

    return (
        <main className="container mx-auto px-4 py-8 md:py-16 text-center">
        <PageHelmet title="Episodes" />
        <h1 className="text-3xl md:text-5xl font-bold mb-4 md:mb-6">Podcast Episodes</h1>
        <div className="shows-container">
            <div className="shows-list">
                {sortedEpisodes.map((episode) => (
                    <div 
                        key={episode.episodes_uuid} 
                        className="show-item cursor-pointer hover:opacity-80 transition-opacity duration-300"
                        onClick={() => handleEpisodeClick(episode.episodes_uuid)}
                    >
                        <img src={episode.episode_image} alt={episode.title} className="show-image" />
                        <h2 className="show-title">{episode.title}</h2>
                    </div>
                ))}
            </div>
        </div>
      </main>
    );
};

export default EpisodesPage;
