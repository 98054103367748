import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import healthReducer from './healthSlice';
import allShowsReducer from './allShowsSlice';
import episodesReducer from './episodesSlice';
import episodeInfoReducer from './episodeInfoSlice';
import showInfoReducer from './showInfoSlice';
import personInfoReducer from './personInfoSlice';
import excerptInfoReducer from './excerptInfoSlice';
import feedReducer from './feedSlice';
import queryReducer from './querySlice';
import exploreReducer from './exploreSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    episodeInfo: episodeInfoReducer,
    episodes: episodesReducer,
    excerptInfo: excerptInfoReducer,
    feed: feedReducer,
    health: healthReducer,
    query: queryReducer,
    personInfo: personInfoReducer,
    showInfo: showInfoReducer,
    shows: allShowsReducer,
    explore: exploreReducer,
  },
});
