import React from 'react';
import { Helmet } from 'react-helmet';

const PageHelmet = ({ title, children }) => {
  const baseTitle = 'Pod Nebula';
  const fullTitle = title ? `${title} | ${baseTitle}` : baseTitle;

  return (
    <Helmet>
      <title>{fullTitle}</title>
      {children}
    </Helmet>
  );
};

export default PageHelmet;
