import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchExcerptByUUID, clearExcerptData } from '../store/excerptInfoSlice';
import AuthenticationSpinner from './AuthenticationSpinner';
import LoadingSpinner from './LoadingSpinner';
import ExcerptPlayer from './ExcerptPlayer';

const ExcerptPage = () => {
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const { data: excerptData, loading, error } = useSelector(state => state.excerptInfo);
  const { isAuthenticated, isLoading: authLoading } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!authLoading && isAuthenticated && uuid) {
      dispatch(fetchExcerptByUUID(uuid));
    }

    return () => {
      dispatch(clearExcerptData());
    };
  }, [uuid, dispatch, isAuthenticated, authLoading]);

  if (authLoading) {
    return <AuthenticationSpinner />;
  }

  if (!isAuthenticated) {
    return null;
  }

  if (loading) {
    return <LoadingSpinner text="Loading excerpt..." />;
  }

  if (error) return <div>Error: {error}</div>;

  return <ExcerptPlayer excerpt={excerptData} />;
};

export default ExcerptPage;
