import React from 'react';
import styles from '../styles/PersonImage.module.css';

const PersonImage = ({ src, alt, size = 40 }) => {
  return (
    <div 
      className={styles.personImageContainer} 
      style={{ width: `${size}px`, height: `${size}px` }}
    >
      {src ? (
        <img 
          src={src} 
          alt={alt} 
          className={styles.personImage}
        />
      ) : (
        <div className={styles.personImagePlaceholder}>
          {alt ? alt.charAt(0).toUpperCase() : '?'}
        </div>
      )}
    </div>
  );
};

export default PersonImage;
