import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import styles from '../styles/EntityList.module.css';

function EntityList({ entities }) {
  if (!entities || entities.length === 0) {
    return <h4 className={styles.noEntitiesMessage}>No entities identified</h4>
  }

  const sortedEntities = [...entities].sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div className={styles.entityContainer}>
      <div className={styles.expanded}>
        <div className={styles.entitiesList}>
          {sortedEntities.map((entity, index) => (
            <div 
              key={index} 
              className={`${styles.entityItem} ${styles.clickable}`}
              onClick={() => window.open(entity.link, '_blank', 'noopener,noreferrer')}
            >
              <h4 className={styles.entityName}>
                {entity.name}
              </h4>
              <div className={styles.entityType}>
                <FaInfoCircle className={styles.infoIcon} /> {entity.type}
              </div>
              {entity.mention_summary && (
                <p className={styles.entityDetails}>{entity.mention_summary}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default EntityList;
