import React from 'react';
import { FaPlay } from 'react-icons/fa';
import Tags from './Tags';
import styles from '../styles/ExcerptsTable.module.css';

const ExcerptsTable = ({ excerpts, onSelectExcerpt, onTagSelect }) => {
  const handlePersonSelect = (personsUuid) => {
    window.open(`/persons/${personsUuid}`, '_blank');
  };

  const handleShowSelect = (showsUuid) => {
    window.open(`/shows/${showsUuid}`, '_blank');
  };

  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th></th>
            <th>Title</th>
            <th>Show</th>
            <th>Guest</th>
            <th>Tags</th>
          </tr>
        </thead>
        <tbody>
          {excerpts.map((excerpt, index) => (
            <tr key={excerpt.excerpt.excerpts_uuid}>
              <td className={styles.imageCell}>
                <div className={styles.thumbnailContainer} onClick={() => onSelectExcerpt(excerpt, index)}>
                  <img 
                    src={excerpt.episode.episode_image} 
                    alt={excerpt.excerpt.title} 
                    className={styles.thumbnailImage}
                  />
                  <div className={styles.playOverlay}>
                    <FaPlay className={styles.playIcon} />
                  </div>
                </div>
              </td>
              <td>
                <span 
                  className={styles.title}
                  onClick={() => onSelectExcerpt(excerpt, index)}
                >
                  {excerpt.excerpt.title}
                </span>
              </td>
              <td>
                <span 
                  className={styles.showLink}
                  onClick={() => handleShowSelect(excerpt.show.shows_uuid)}
                >
                  {excerpt.show.title}
                </span>
              </td>
              <td>
                {excerpt.guests.length > 0 && (
                  <span 
                    className={styles.guestLink}
                    onClick={() => handlePersonSelect(excerpt.guests[0].persons_uuid)}
                  >
                    {excerpt.guests[0].name}
                  </span>
                )}
              </td>
              <td className={styles.tagsCell}>
                <Tags 
                  tags={excerpt.excerpt.tags} 
                  onTagSelect={onTagSelect}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ExcerptsTable;
