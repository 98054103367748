import React, { useState, useMemo } from 'react';
import styles from '../styles/ExcerptTags.module.css';
import { getTagColor, getContrastColor } from '../utils/utils';

function ExcerptTags({ tagCounts, selectedTags, onTagClick, onShowAll, notShowTitle, notShowAll, notColorfulButtons }) {
  const maxTagCount = useMemo(() => {
    return Math.max(...Object.values(tagCounts), 1);
  }, [tagCounts]);

  const allTags = useMemo(() => {
    return Object.keys(tagCounts).sort((a, b) => tagCounts[b] - tagCounts[a]);
  }, [tagCounts]);

  const visibleTags = allTags;

  const getTagSize = (tag) => {
    const count = tagCounts[tag];
    return Math.floor((count / maxTagCount) * 8);
  };

  return (
    <div className={styles.topicsContainer}>
      <div className={styles.topicsHeader}>
        {!notShowTitle && <h3 className={styles.topicsTitle}>Topics ({allTags.length})</h3>}
      </div>
      <div className={styles.tagsWrapper}>
        <div className={styles.tagsList}>
          {!notShowAll && (
            <button 
              className={`${styles.tagBubble} ${styles.showAllButton} ${selectedTags.length === 0 ? styles.selected : ''}`}
              onClick={onShowAll}
              style={{
                backgroundColor: selectedTags.length === 0 ? '#4CAF50' : '#f0f0f0',
                color: selectedTags.length === 0 ? '#FFFFFF' : '#000000',
              }}
            >
              Show All
            </button>
          )}
          {visibleTags.map(tag => {
            const bgColor = selectedTags.includes(tag) ? getTagColor(tag) : '#f0f0f0';
            const textColor = selectedTags.includes(tag) ? getContrastColor(bgColor) : '#000000';
            return (
              <button 
                key={tag} 
                className={`${styles.tagBubble} ${selectedTags.includes(tag) ? styles.selected : ''}`}
                onClick={() => onTagClick(tag)}
                style={{
                  backgroundColor: bgColor,
                  color: textColor,
                  padding: `${4 + getTagSize(tag)}px ${8 + getTagSize(tag) * 2}px`,
                  fontSize: `${0.8 + (getTagSize(tag) * 0.15)}em`,
                  fontWeight: getTagSize(tag) > 2 ? 'bold' : 'normal',
                }}
              >
                {tag}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ExcerptTags;
