import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/ShowsList.module.css';

const ShowsList = ({ shows, title }) => {
  return (
    <div className={styles.showsListContainer}>
      <h2 className={styles.showsListTitle}>{title}</h2>
      <div className={styles.showsGrid}>
        {shows.map((show) => (
          <Link to={`/shows/${show.shows_uuid}`} key={show.shows_uuid} className={styles.showCard}>
            <img 
              src={show.shows_image} 
              alt={show.title} 
              className={styles.showImage}
            />
            <div className={styles.showTitle}>{show.title}</div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ShowsList;
