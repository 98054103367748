import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaPlay, FaCalendar } from 'react-icons/fa';
import styles from '../styles/Episode.module.css';
import EpisodeImage from './EpisodeImage';
import Person from './Person';

const Episode = ({ episode, guests }) => {
  const navigate = useNavigate();

  const handleEpisodeClick = (e) => {
    if (!e.metaKey && !e.ctrlKey) {
      e.preventDefault();
      navigate(`/episodes/${episode.episodes_uuid}`);
    }
  };

  return (
    <a 
      href={`/episodes/${episode.episodes_uuid}`}
      className={styles.episodeContainer} 
      onClick={handleEpisodeClick}
    >
      <div className={styles.imageWrapper}>
        <EpisodeImage imageUrl={episode.episode_image} altText={episode.title} />
      </div>
      <div className={styles.episodeInfo}>
        <h3 className={styles.episodeTitle}>{episode.title}</h3>
        <p className={styles.episodeDate}>
          <FaCalendar className={styles.calendarIcon} />
          {new Date(episode.published_date).toLocaleDateString()}
        </p>
        {guests && guests.length > 0 && (
          <div className={styles.guestsList}>
            {guests.map((guest) => (
              <Person key={guest.persons_uuid} person={guest} size={40} />
            ))}
          </div>
        )}
      </div>
      <FaPlay className={styles.playIcon} />
    </a>
  );
};

export default Episode;
