import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDHscF8OdnyvBerhcxGhjAMOCR0pGQTxWk",
    authDomain: "pod-nebula.firebaseapp.com",
    projectId: "pod-nebula",
    storageBucket: "pod-nebula.appspot.com",
    messagingSenderId: "504589534487",
    appId: "1:504589534487:web:a0e840406698813d3ba352",
    measurementId: "G-PZPBEQQ5YR"
  };

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
