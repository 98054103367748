import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllShows } from '../store/allShowsSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';
import AuthenticationSpinner from './AuthenticationSpinner';
import '../styles/ShowsPage.css';
import PageHelmet from './PageHelmet';

const ShowsPage = () => {
    const dispatch = useDispatch();
    const { data, loading: showsLoading, error } = useSelector((state) => state.shows);
    const { isAuthenticated, isLoading: authLoading } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!authLoading) {
            if (isAuthenticated) {
                dispatch(fetchAllShows());
            } else {
                // Store the current path in local storage
                localStorage.setItem('redirectAfterAuth', location.pathname);
                // Redirect to auth page with a 'from' parameter
                navigate('/auth?from=' + encodeURIComponent(location.pathname));
            }
        }
    }, [dispatch, isAuthenticated, authLoading, navigate, location]);

    if (authLoading) {
        return <AuthenticationSpinner />;
    }

    if (!isAuthenticated) {
        return null;
    }

    if (showsLoading) {
        return <LoadingSpinner text="Loading podcast shows..." />;
    }
  
    if (error) {
        return <div>Error: {error}</div>;
    }

    const handleShowClick = (showUuid) => {
        navigate(`/shows/${showUuid}`);
    };

    return (
        <main className="container mx-auto px-4 py-8 md:py-16 text-center">
            <PageHelmet title="Shows" />
            <h1 className="text-3xl md:text-5xl font-bold mb-4 md:mb-6">Podcast Shows</h1>
            <div className="shows-container">
            <div className="shows-list">
                {data && data.shows && data.shows.map((show) => (
                    <div 
                        key={show.shows_uuid} 
                        className="show-item cursor-pointer hover:opacity-80 transition-opacity duration-300"
                        onClick={() => handleShowClick(show.shows_uuid)}
                    >
                        <img src={show.shows_image} alt={show.title} className="show-image" />
                        <h2 className="show-title">{show.title}</h2>
                    </div>
                ))}
                </div>
            </div>
        </main>
    );
};

export default ShowsPage;
