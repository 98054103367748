import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchShowByUUID, fetchShowEpisodes, clearShowData, fetchShowTopics } from '../store/showInfoSlice';
import LoadingSpinner from './LoadingSpinner';
import AuthenticationSpinner from './AuthenticationSpinner';
import Person from './Person';
import { FaPlay, FaGlobe, FaShareSquare, FaFilter } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from '../styles/ShowPage.module.css';
import EpisodeList from './EpisodeList';
import PageHelmet from './PageHelmet';
import TopicsList from './TopicsList';

const ShowPage = () => {
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const showState = useSelector(state => state.showInfo) || {};
  const { data, episodes, loading, episodesLoading, error, topics, topicsLoading } = showState;
  const { isAuthenticated, isLoading: authLoading } = useSelector((state) => state.auth);
  const [showData, setShowData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [episodesPerPage, setEpisodesPerPage] = useState(10);
  const [episodeType, setEpisodeType] = useState('ingested');
  const [topicsCurrentPage, setTopicsCurrentPage] = useState(1);
  const [topicsPerPage, setTopicsPerPage] = useState(120);

  useEffect(() => {
    if (!authLoading && isAuthenticated && uuid) {
      setShowData(null);
      dispatch(fetchShowByUUID(uuid));
      fetchEpisodePage(1);
      fetchTopicsPage(1);
    }

    return () => {
      setShowData(null);
      dispatch(clearShowData());
    };
  }, [uuid, isAuthenticated, authLoading]);

  useEffect(() => {
    if (!loading && !error && data !== null) {
      const sortedGuests = [...data.guests].sort((a, b) => 
        a.last_name.localeCompare(b.last_name)
      );
      const sortedHosts = [...data.hosts].sort((a, b) => 
        a.last_name.localeCompare(b.last_name)
      );
      setShowData({
        ...data,
        guests: sortedGuests,
        hosts: sortedHosts,
        // episodes will now come from episodes state instead
      });
    }
  }, [data, loading, error]);

  const handlePlayFeed = () => {
    if (showData && showData.show) {
      navigate(`/feed?shows_uuid=${showData.show.shows_uuid}`);
    }
  };

  const handleShareClick = () => {
    const currentUrl = window.location.href;
    navigator.clipboard.writeText(currentUrl).then(() => {
      toast.success('Show Link copied to clipboard', {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        className: styles.toastSuccess,
      });
    });
  };

  const fetchEpisodePage = (page) => {
    dispatch(fetchShowEpisodes({ 
      uuid,
      params: {
        episodeType,
        numEpisodes: episodesPerPage,
        pageNumber: page
      }
    }));
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchEpisodePage(newPage);
  };

  const handleTypeToggle = () => {
    const newType = episodeType === 'all' ? 'ingested' : 'all';
    setEpisodeType(newType);
    setCurrentPage(1);
    dispatch(fetchShowEpisodes({ 
      uuid,
      params: {
        episodeType: newType,
        numEpisodes: episodesPerPage,
        pageNumber: 1
      }
    }));
  };

  const fetchTopicsPage = (page) => {
    dispatch(fetchShowTopics({ 
      uuid,
      params: {
        numTopics: topicsPerPage,
        pageNumber: page
      }
    }));
  };

  const handleTopicsPageChange = (newPage) => {
    setTopicsCurrentPage(newPage);
    fetchTopicsPage(newPage);
  };

  const handleTagSelect = (tagName, tag) => {
    window.open(`/feed?tags_uuid=${tag.tags_uuid}`, '_blank');
  };

  if (authLoading) {
    return <AuthenticationSpinner />;
  }

  if (!isAuthenticated) {
    return null;
  }

  if (loading) {
    return <LoadingSpinner text="Loading show..." />;
  }

  if (showData == null) {
    return <LoadingSpinner text="Processing show..." />;
  }

  if (error) return <div>Error: {error}</div>;

  return (
    <div className={styles.showContainer}>
      {showData && (
        <PageHelmet title={showData.show.title} />
      )}
      <ToastContainer
        toastClassName={styles.customToast}
        progressClassName={styles.customProgress}
      />
      <main className={styles.mainContent}>
        <div className={styles.showHeader}>
          <div className={styles.showImageContainer}>
            {showData && showData.show.shows_image && (
              <img 
                className={styles.showImage} 
                src={showData.show.shows_image} 
                alt={showData.show.title || 'Show image'} 
              />
            )}
          </div>
          <div className={styles.showInfo}>
            <h1 className={styles.showTitle}>{showData.show.title}</h1>
            <p className={styles.showDescription}>{showData.show.description}</p>
            
            {showData.hosts && showData.hosts.length > 0 && (
              <div className={styles.hostsSection}>
                <ul className={styles.hostsList}>
                  {showData.hosts.map((host) => (
                    <li key={host.persons_uuid} className={styles.hostItem}>
                      <Person person={host} />
                    </li>
                  ))}
                </ul>
              </div>
            )}
            
            <div className={styles.buttonSection}>
              <button 
                className={styles.playFeedButton}
                onClick={handlePlayFeed}
              >
                <FaPlay className={styles.buttonIcon} />
                Play Feed
              </button>
              {showData.show.url && (
                <button 
                  className={styles.showSiteButton}
                  onClick={() => window.open(showData.show.url, '_blank', 'noopener,noreferrer')}
                >
                  <FaGlobe className={styles.buttonIcon} />
                  Source
                </button>
              )}
              <button 
                className={styles.shareButton}
                onClick={handleShareClick}
              >
                <FaShareSquare className={styles.buttonIcon} />
                Share
              </button>
            </div>
          </div>
        </div>
        
        <TopicsList
          title="Popular Topics"
          topics={topics.items}
          loading={topicsLoading}
          totalCount={topics.totalCount}
          currentPage={topicsCurrentPage}
          onPageChange={handleTopicsPageChange}
          topicsPerPage={topicsPerPage}
          includePagination={false}
          onTopicSelect={handleTagSelect}
        />

        {showData.guests && showData.guests.length > 0 && (
          <div className={styles.guestsSection}>
            <h2 className={styles.sectionTitle}>Guests</h2>
            <div className={styles.guestsList}>
              {showData.guests.map((guest) => (
                <div key={guest.persons_uuid} className={styles.guestItem}>
                  <Person person={guest} />
                </div>
              ))}
            </div>
          </div>
        )}
        
        <EpisodeList
          title={episodeType === 'all' ? 'All Episodes' : 'Ingested Episodes'}
          episodes={episodes.items} 
          loading={episodesLoading}
          totalCount={episodes.totalCount}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          episodesPerPage={episodesPerPage}
          episodeType={episodeType}
          onTypeToggle={handleTypeToggle}
        />
      </main>
    </div>
  );
};

export default ShowPage;
