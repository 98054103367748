import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchHealthCheck } from '../store/healthSlice';
import '../styles/HealthCheckPage.css';
import PageHelmet from './PageHelmet';

const HealthCheckPage = () => {
    const dispatch = useDispatch();
    const backendResponse = useSelector((state) => state.health);
  
    useEffect(() => {
      dispatch(fetchHealthCheck());
    }, [dispatch]);
  
    if (backendResponse.loading) {
      return <div>Loading health check...</div>;
    }
  
    if (backendResponse.error) {
      return <div>Error: {backendResponse.error}</div>;
    }

    return (
        <div className="health-check-container">
            <PageHelmet title="Health Check" />
            <h1 className="health-check-title">Health Check</h1>
            <div className="health-check-status">
                <p className="status-item">
                    <span className="status-label">Status:</span>
                    <span className={`status-value ${backendResponse.data && backendResponse.data.status ? backendResponse.data.status.toLowerCase() : ''}`}>
                        {backendResponse.data && backendResponse.data.status || 'N/A'}
                    </span>
                </p>
                <p className="status-item">
                    <span className="status-label">Server Time:</span>
                    <span className="status-value">{backendResponse.data && backendResponse.data.server_time || 'No server_time available'}</span>
                </p>
                <p className="status-item">
                    <span className="status-label">DB Time:</span>
                    <span className="status-value">{backendResponse.data && backendResponse.data.db_time || 'No db_time available'}</span>
                </p>
            </div>
            <button className="refresh-button" onClick={() => dispatch(fetchHealthCheck())}>
                Refresh
            </button>
        </div>
      );
  };
  
  export default HealthCheckPage;
