import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { postExploreResults } from '../api/api';

export const fetchExploreResults = createAsyncThunk(
  'explore/fetchResults',
  async (params, { rejectWithValue }) => {
    try {
      const response = await postExploreResults(params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const exploreSlice = createSlice({
  name: 'explore',
  initialState: {
    data: null,
    status: 'idle',
    error: null,
    currentPage: 1,
    hasMore: true
  },
  reducers: {
    clearExploreData: (state) => {
      state.data = null;
      state.status = 'idle';
      state.error = null;
      state.currentPage = 1;
      state.hasMore = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExploreResults.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchExploreResults.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
        state.hasMore = action.payload.excerpts.length === action.payload.pagination.limit;
        state.currentPage = action.payload.pagination.page;
      })
      .addCase(fetchExploreResults.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { clearExploreData } = exploreSlice.actions;

export default exploreSlice.reducer;
