import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllShows } from '../store/allShowsSlice';
import ShowImage from './ShowImage';
import LoadingSpinner from './LoadingSpinner';
import styles from '../styles/ShowFilterModal.module.css';

const ShowFilterModal = ({ onClose, selectedShows, onShowSelect }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useDispatch();
  const { data: showsData, loading, error } = useSelector((state) => state.shows);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(fetchAllShows({
        query: searchQuery.trim(),
        page: 1,
        limit: 20
      }));
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery, dispatch]);

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        <button className={styles.closeButton} onClick={onClose}>×</button>
        <h2 className={styles.modalTitle}>Shows</h2>
        
        <div className={styles.searchContainer}>
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search shows..."
            className={styles.searchInput}
          />
        </div>

        <div className={styles.listContainer}>
          {error && <div className={styles.error}>{error}</div>}
          
          {showsData?.shows?.map(show => (
            <div 
              key={show.shows_uuid}
              className={`${styles.showItem} ${
                selectedShows.includes(show.shows_uuid) ? styles.selected : ''
              }`}
              onClick={() => onShowSelect(show)}
            >
              <div className={styles.showInfo}>
                <ShowImage 
                  src={show.shows_image} 
                  alt={show.title}
                  size={40}
                />
                <span className={styles.showTitle}>{show.title}</span>
              </div>
            </div>
          ))}

          {loading && (
            <LoadingSpinner 
              size={30} 
              text={searchQuery ? 'Searching shows...' : 'Loading shows...'}
            />
          )}
        </div>

        <div className={styles.modalFooter}>
          <span className={styles.resultCount}>
            {showsData?.total_count || 0} {showsData?.total_count === 1 ? 'show' : 'shows'} found
          </span>
        </div>
      </div>
    </div>
  );
};

export default ShowFilterModal;
