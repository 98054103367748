import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getFeed } from '../api/api';

export const fetchFeed = createAsyncThunk(
  'feed/fetchFeed',
  async (filter) => {
    const response = await getFeed(filter);
    return response;
  }
);

const feedSlice = createSlice({
  name: 'feed',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {
    clearFeedData: (state) => {
      state.data = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeed.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFeed.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchFeed.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { clearFeedData } = feedSlice.actions;

export default feedSlice.reducer;
