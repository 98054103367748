import React from 'react';
import { FaFilter } from 'react-icons/fa';
import Episode from './Episode';
import LoadingSpinner from './LoadingSpinner';
import styles from '../styles/EpisodeList.module.css';

const EpisodeList = ({ 
  episodes, 
  title = "Episodes",
  loading = false,
  totalCount = 0,
  currentPage = 1,
  onPageChange,
  episodesPerPage = 10,
  episodeType,
  onTypeToggle
}) => {
  if (!episodes && !loading) {
    return null;
  }

  const totalPages = Math.ceil(totalCount / episodesPerPage);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      onPageChange(newPage);
    }
  };

  return (
    <div className={styles.episodesSection}>
      <div className={styles.episodesHeader}>
        <h2 className={styles.sectionTitle}>{title}</h2>
      </div>
      {!loading && (
        <>
          {totalCount > 0 && (
            <span className={styles.episodeCount}>
              {totalCount} episode{totalCount !== 1 ? 's' : ''}
            </span>
          )}
          <button 
            className={styles.typeToggle}
            onClick={onTypeToggle}
          >
            <FaFilter className={styles.buttonIcon} />
            {episodeType === 'all' ? 'Filter Ingested' : 'Show All'}
          </button>
        </>
      )}

      {loading ? (
        <div className={styles.loadingContainer}>
          <LoadingSpinner text="Loading episodes..." />
        </div>
      ) : totalCount === 0 ? (
        <div className={styles.emptyState}>
          <p className={styles.emptyStateText}>
            {episodeType === 'all' ? 'No episodes found' : 'No ingested episodes found'}
          </p>
        </div>
      ) : (
        <>
          <ul className={styles.episodesList}>
            {episodes.map((episodeInfo) => (
              <li key={episodeInfo.episode.episodes_uuid} className={styles.episodeItem}>
                <Episode episode={episodeInfo.episode} guests={episodeInfo.guests} />
              </li>
            ))}
          </ul>

          {totalPages > 1 && (
            <div className={styles.pagination}>
              <button
                className={styles.pageButton}
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              
              <span className={styles.pageInfo}>
                Page {currentPage} of {totalPages}
              </span>

              <button
                className={styles.pageButton}
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default EpisodeList;
