import DOMPurify from 'dompurify';

const SafeHTML = ({ html }) => {
  if (!html) return null;
  
  const sanitizedHTML = DOMPurify.sanitize(html, {
    ALLOWED_TAGS: ['p', 'b', 'i', 'em', 'strong', 'a', 'ul', 'ol', 'li', 'br'],
    ALLOWED_ATTR: ['href', 'target', 'rel']
  });

  return (
    <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
  );
};

export default SafeHTML; 
