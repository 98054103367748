import React from 'react';
import styles from '../styles/FeaturesPage.module.css';
import PageHelmet from './PageHelmet';

const FeatureCard = ({ title, subtitle, gradientColors }) => (
  <div 
    className={styles.featureCard}
    style={{
      background: `linear-gradient(135deg, ${gradientColors[0]} 0%, ${gradientColors[1]} 100%)`
    }}
  >
    <h2 className={styles.featureTitle}>{title}</h2>
    <p className={styles.featureSubtitle}>{subtitle}</p>
  </div>
);

const FeaturesPage = () => {
  return (
    <div className={styles.container}>
      <PageHelmet title="Features" />
      <main className={styles.main}>
        <h1 className={styles.welcome}>The New Standard for Podcasts</h1>
        <div className={styles.featuresGrid}>
          <FeatureCard
            title="Explore With Feeds"
            subtitle="A feed for a show / person / topic. Dive into the episode at any time."
            gradientColors={['#9333ea', '#c026d3']}
          />
          <FeatureCard
            title="Skip the Clickbait"
            subtitle="Explore the complete back catalog of clips relevant to you."
            gradientColors={['#4f46e5', '#7c3aed']}
          />
          <FeatureCard
            title="Pull It Up Jamie"
            subtitle="What's mentioned always at your fingertips. The people, places, and things."
            gradientColors={['#7c3aed', '#9333ea']}
          />
          <FeatureCard
            title="Learn Anything"
            subtitle="Create and discover playlists for any topic across podcasts."
            gradientColors={['#db2777', '#4f46e5']}
          />
          <FeatureCard
            title="Rich Discovery"
            subtitle="Find exactly what you're looking for based on the actual conversation."
            gradientColors={['#4f46e5', '#db2777']}
          />
          <FeatureCard
            title="Forget 2x Speed"
            subtitle="Customize your listening experience. From 90 minutes to 9, with a tap."
            gradientColors={['#c026d3', '#db2777']}
          />
        </div>
      </main>
    </div>
  );
};

export default FeaturesPage;
