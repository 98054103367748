import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllShows } from '../api/api';

export const fetchAllShows = createAsyncThunk(
  'shows/fetchAllShows',
  async (params = {}, { getState }) => {
    const { shows } = getState();
    
    // If it's a new search or first page, use provided params
    // Otherwise, append to existing data
    const isNewSearch = params.query !== shows.currentQuery || params.page === 1;
    
    const response = await getAllShows({
      query: params.query,
      page: params.page || 1,
      limit: params.limit || 10
    });

    return {
      data: response,
      isNewSearch,
    };
  }
);

const allShowsSlice = createSlice({
  name: 'shows',
  initialState: {
    data: null,
    loading: false,
    error: null,
    currentPage: 1,
    hasMore: true,
    currentQuery: '',
  },
  reducers: {
    clearShowsData: (state) => {
      state.data = null;
      state.loading = false;
      state.error = null;
      state.currentPage = 1;
      state.hasMore = true;
      state.currentQuery = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllShows.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllShows.fulfilled, (state, action) => {
        const { data, isNewSearch } = action.payload;
        
        if (isNewSearch) {
          state.data = data;
        } else {
          // Append new shows to existing ones
          state.data = {
            ...data,
            shows: [...(state.data?.shows || []), ...data.shows]
          };
        }
        
        state.loading = false;
        state.currentPage = data.pagination.page;
        state.hasMore = data.shows.length === data.pagination.limit;
        state.currentQuery = data.pagination.query || '';
      })
      .addCase(fetchAllShows.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { clearShowsData } = allShowsSlice.actions;

export default allShowsSlice.reducer;
