import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { healthCheck } from '../api/api';

export const fetchHealthCheck = createAsyncThunk(
  'health/fetchHealthCheck',
  async () => {
    const response = await healthCheck();
    return response;
  }
);

const healthSlice = createSlice({
  name: 'health',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHealthCheck.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchHealthCheck.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchHealthCheck.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default healthSlice.reducer;
