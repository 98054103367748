import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { submitQuery, clearQueryData } from '../store/querySlice';
import Feed from './Feed';
import LoadingSpinner from './LoadingSpinner';
import AuthenticationSpinner from './AuthenticationSpinner';
import PageHelmet from './PageHelmet';
import styles from '../styles/SearchPage.module.css';

const SearchPage = () => {
  const [query, setQuery] = useState('');
  const dispatch = useDispatch();
  const { data, status, error } = useSelector((state) => state.query);
  const { isAuthenticated, isLoading: authLoading } = useSelector((state) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authLoading) {
      if (!isAuthenticated) {
        localStorage.setItem('redirectAfterAuth', location.pathname + location.search);
        navigate('/auth?from=' + encodeURIComponent(location.pathname + location.search));
      }
    }
  }, [isAuthenticated, authLoading, navigate, location]);

  useEffect(() => {
    return () => {
      dispatch(clearQueryData());
    };
  }, [dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (query.trim()) {
      dispatch(submitQuery(query));
    }
  };

  if (authLoading) {
    return <AuthenticationSpinner />;
  }

  if (!isAuthenticated) {
    return null;
  }

  return (
    <>
      <PageHelmet title="Search" />
      <div className={styles.searchContainer}>
        <form onSubmit={handleSubmit} className={styles.searchForm}>
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Search topics..."
            className={styles.searchInput}
          />
          <button type="submit" className={styles.searchButton}>
            Search Pod Nebula
          </button>
        </form>

        {status === 'loading' && <LoadingSpinner text="Searching..." />}
        
        {status === 'failed' && <div className={styles.error}>Error: {error}</div>}
        
        {status === 'succeeded' && data && (
          <Feed excerpts={data.excerpts} />
        )}
        
        {status === 'succeeded' && (!data || data.excerpts.length === 0) && (
          <div className={styles.noResults}>No results found</div>
        )}
      </div>
    </>
  );
};

export default SearchPage;
