import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllShows } from '../store/allShowsSlice';
import { fetchEpisodes } from '../store/episodesSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';
import AuthenticationSpinner from './AuthenticationSpinner';
import styles from '../styles/BrowsePage.module.css';
import PageHelmet from './PageHelmet';

const BrowsePage = () => {
    const dispatch = useDispatch();
    const { data: showsData, loading: showsLoading, error: showsError, hasMore } = useSelector((state) => state.shows);
    const { data: episodesData, loading: episodesLoading, error: episodesError } = useSelector((state) => state.episodes);
    const { isAuthenticated, isLoading: authLoading } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const location = useLocation();
    const [recentEpisodes, setRecentEpisodes] = useState([]);

    const truncateTitle = (title, maxLength = 50) => {
        if (title.length <= maxLength) return title;
        return title.slice(0, maxLength - 3) + '...';
    };

    useEffect(() => {
        if (!authLoading) {
            if (isAuthenticated) {
                dispatch(fetchAllShows({ page: 1, limit: 20 }));
                dispatch(fetchEpisodes());
            } else {
                localStorage.setItem('redirectAfterAuth', location.pathname);
                navigate('/auth?from=' + encodeURIComponent(location.pathname));
            }
        }
    }, [dispatch, isAuthenticated, authLoading, navigate, location]);

    useEffect(() => {
        if (episodesData && episodesData.episodes) {
            const sorted = [...episodesData.episodes]
                .sort((a, b) => new Date(b.published_date) - new Date(a.published_date))
                .slice(0, 10);
            setRecentEpisodes(sorted);
        }
    }, [episodesData]);

    const loadMoreShows = () => {
        if (hasMore && !showsLoading) {
            dispatch(fetchAllShows({ page: showsData.pagination.page + 1, limit: 20 }));
        }
    };

    if (authLoading) {
        return <AuthenticationSpinner />;
    }

    if (!isAuthenticated) {
        return null;
    }

    if (showsLoading && !showsData) {
        return <LoadingSpinner text="Loading content..." />;
    }
  
    if (showsError || episodesError) {
        return <div>Error: {showsError || episodesError}</div>;
    }

    const handleShowClick = (showUuid) => {
        navigate(`/shows/${showUuid}`);
    };

    const handleEpisodeClick = (episodeUuid) => {
        navigate(`/episodes/${episodeUuid}`);
    };

    return (
        <main className={styles['main-container']}>
            <PageHelmet title="Browse" />
            <section className="mb-12">
                <div className={styles['browse-container']}>
                    <h2 className={`text-2xl md:text-3xl font-bold ${styles['browse-header']}`}>Recent Episodes</h2>
                    <div className={styles['browse-list']}>
                        {recentEpisodes.map((episode) => (
                            <div 
                                key={episode.episodes_uuid} 
                                className={`${styles['browse-item']} cursor-pointer`}
                                onClick={() => handleEpisodeClick(episode.episodes_uuid)}
                            >
                                <img src={episode.episode_image} alt={episode.title} className={styles['browse-image']} />
                                <h3 className={styles['browse-title']}>{truncateTitle(episode.title)}</h3>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section>
                <div className={styles['browse-container']}>
                    <h2 className={`text-2xl md:text-3xl font-bold ${styles['browse-header']}`}>Shows</h2>
                    <div className={styles['browse-list']}>
                        {showsData && showsData.shows && showsData.shows.map((show) => (
                            <div 
                                key={show.shows_uuid} 
                                className={`${styles['browse-item']} cursor-pointer`}
                                onClick={() => handleShowClick(show.shows_uuid)}
                            >
                                <img src={show.shows_image} alt={show.title} className={styles['browse-image']} />
                                <h3 className={styles['browse-title']}>{truncateTitle(show.title)}</h3>
                            </div>
                        ))}
                    </div>
                    {hasMore && (
                        <div className={styles['load-more-container']}>
                            <button 
                                onClick={loadMoreShows}
                                className={styles['load-more-button']}
                                disabled={showsLoading}
                            >
                                {showsLoading ? 'Loading...' : 'Load More Shows'}
                            </button>
                        </div>
                    )}
                </div>
            </section>
        </main>
    );
};

export default BrowsePage;
